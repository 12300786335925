<template>
  <div class="detail">
    <div class="Home_order" v-if="HomeList.length > 0">
      <van-list finished-text="没有更多了">
        <div
          class="orderItem"
          v-for="iitm in HomeList"
          :key="iitm.id"
          @click="viewDetail(iitm)"
        >
          <div class="item_top g-flex-jb g-flex-ac">
            <div
              class="ml-30 item_tit_le fs-32"
              style="color: #FDC95B;"
              v-if="iitm.exa_status === 0"
            >
              审核中
            </div>
            <div
              class="ml-30 item_tit_le fs-32"
              style="color: #444CF0;"
              v-else-if="iitm.exa_status === 1"
            >
              审核通过
            </div>
            <div
              class="ml-30 item_tit_le fs-32"
              style="color: #FB7320;"
              v-else-if="iitm.exa_status === 2"
            >
              审核不通过
            </div>
            <div
              class="ml-30 item_tit_le fs-32"
              style="color: #666666;"
              v-else-if="iitm.exa_status === 3"
            >
              已失效
            </div>
            <div class="ml-30 item_tit_le fs-32" style="color: #666666;" v-else>
              完成
            </div>
            <div class="item_tit_ri fs-28" v-if="iitm.order_type === 1">
              权益单
            </div>
            <div class="item_tit_ri fs-28" v-else>理赔单</div>
          </div>
          <div class="mt-30 g-flex-ac">
            <div class="ml-30 fs-28 left_wid" style="color:#999">权益类型:</div>
            <div class="fs-28" style="color:#333">{{ iitm.instype_name }}</div>
          </div>
          <div class="mt-30 g-flex-ac">
            <div class="ml-30 fs-28 left_wid" style="color:#999">参保人:</div>
            <div class="fs-28" style="color:#333">{{ iitm.name }}</div>
          </div>
          <div class="mt-30 g-flex-ac">
            <div class="ml-30 fs-28 left_wid" style="color:#999">生效期限:</div>
            <div
              class="fs-28"
              style="color:#333"
              v-if="iitm.term_time_start && iitm.term_time_end"
            >
              {{ iitm.term_time_start }}-{{ iitm.term_time_end }}
            </div>
          </div>
          <div class="mt-30 g-flex-ac">
            <div class="ml-30 fs-28 left_wid" style="color:#999">接待人员:</div>
            <div class="fs-28" style="color:#333">
              {{ iitm.shop_member_name }}
            </div>
          </div>
          <div class="bot_img g-flex-dft">
            <div class="img_left">
              <img :src="iitm.tem_img" alt />
            </div>
            <div>
              <div class="img_right fs-28 mt-20 ml-20">
                {{ iitm.brand }} {{ iitm.model_name }}
              </div>
              <div
                class="img_right_bot fs-24 ml-20"
                v-if="iitm.ins_service_name"
              >
                {{ iitm.ins_service_name }}
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  beforeRouteLeave(to, from, next) {
    //跳转到搜索页面时，search为搜索页面名称
    if (to.name == "orderHome") {
      to.meta.isUseCache = true;
    }
    next();
  },
  data() {
    return {
      HomeList: [],
      //   loading:false,
      //   finished:false,
    };
  },
  mounted() {
    this.HomeList = JSON.parse(sessionStorage.getItem("data"));
    console.log(this.HomeList, "123");
  },
  methods: {
    viewDetail(item) {
      /*
       * * 1 '碎屏服务注册',
       * 2 "安卓全面保障注册",
       * 3"苹果换机保障注册",
       * 4 "年年焕新注册",
       * 5 "IMEI更换",
       * 6 '碎屏服务履约',
       * 7 '安卓全面保障履约',
       * 8 '苹果换机保障履约',
       * 9 '年年焕新履约'
       * 10 '亚丁智选保障注册' 2021-10-11更新
       * 11 '亚丁智选履约' 2021-10-11更新
       * 12 '智选华为售后注册'
       * 13  智选华为售后履约
       * 14 '智选苹果售后注册 2022-4-14
       * 15  智选苹果售后履约 2022-4-14
       * 16  延保服务注册 2022-5-5*/
      let route = "";
      switch (item.mold) {
        //todo 新增保险要改的地方
        //注册页面
        case 1:
        case 2:
        case 3:
        case 4:
        case 10:
        case 12:
        case 14:
        case 16:
        case 20://// 新增up+全心服务
          route = {
            path: "/RightApply/detailsApply",
            query: { id: item.order_id, canOperate: 0 },
          };
          break;

        case 5:
          route = {
            name: "ReplaceIMEIDetail",
            params: { id: item.order_imei_id, canOperate: 0 },
          };
          break;
        //理赔页面
        case 6:
        case 7:
        case 8:
        case 9:
        case 11:
        case 13:
        case 15:
        case 17:
        case 21://// 新增up+全心服务
          route = {
            name: "ClaimSettlementDetail",
            params: {
              id: item.claims_id,
            },
            query: {
              canOperate: 0,
            },
          };
          break;
      }
      this.$router.push(route);
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  .Home_order {
    padding-bottom: 50px;
    .orderItem {
      margin: 30px auto;
      width: calc(100vw - 60px);
      padding-bottom: 5px;
      background: #ffffff;
      box-shadow: 0px 4px 21px 9px rgba(196, 197, 197, 0.4);
      border-radius: 20px;
      .bot_img {
        width: calc(100vw - 80px);
        margin: 20px auto;
        height: 150px;
        background: #f6f8fe;
        border-radius: 16px;
        .img_left {
          margin-left: 20px;
          width: 126px;
          height: 130px;
          img {
            margin-top: 10px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .img_right {
          height: 27px;
          color: #333333;
        }
        .img_right_bot {
          margin-top: 60px;
          //width: 110px;
          height: 32px;
          background: #8eaee5;
          border-radius: 6px;
          color: white;
          text-align: center;
          line-height: 32px;
        }
      }
      .left_wid {
        width: 150px;
      }
      .item_top {
        height: 70px;
        width: 100%;
        border-bottom: 1px solid #f9f9f9;
        .item_tit_le {
          width: 180px;
          height: 72px;
          line-height: 72px;
        }
        .item_tit_ri {
          width: 148px;
          height: 72px;
          background: linear-gradient(
            -90deg,
            #e1f0fe 0%,
            rgba(225, 240, 254, 0) 100%
          );
          border-radius: 0px 20px 0px 0px;
          border-radius: 0px 20px 0px 0px;
          color: #0080ff;
          text-align: center;
          line-height: 72px;
        }
      }
    }
  }
}
</style>
